import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 295px;
  .overall-card-wrapper {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    .violation-count-wrapper {
      background: white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .chart-container {
        padding-top: 55px;
      }
    }
  }
`
